<template>
	<v-list>
		<v-divider />
		<template v-for="(treeStructure, index) in value">
			<ECMCatalogListItem :key="index" :is-selected="selectedTreeStructure && selectedTreeStructure.id === treeStructure.id" :value="treeStructure" />
			<v-divider :key="'divider-' + index" />
		</template>
	</v-list>
</template>

<script>
import ECMCatalogModuleGuard from '@/mixins/ModulesGuards/ECMCatalog/ECMCatalogModuleGuard'

export default {
	name: 'ECMCatalogList',
	components: {
		ECMCatalogListItem: () => ({
			component: import('@/components/ECMCatalog/ECMCatalogListItem')
		})
	},
	mixins: [ECMCatalogModuleGuard],
	props: {
		value: {
			required: true,
			type: Array
		}
	},
	data: function () {
		return {
			loading: false,
			selectedTreeStructure: {}
		}
	},
	methods: {
		getModuleEventsActionsMapping: function () {
			return [{ event: this.events.TREE_STRUCTURE_SELECTED, action: this.onTreeStructureSelected }]
		},
		onTreeStructureSelected: function (treeStructure) {
			this.selectedTreeStructure = treeStructure
		}
	}
}
</script>
